// enum type name 을 변경하면 lokalise 에 영향이 있음
// sheet.notice.precaution_fifth
// sheet.id_types 확인 필요
export enum IdCardTypeEnum {
  KOREAN_REGISTRATION = 1,
  ALIEN_REGISTRATION,
  PASSPORT,
  KOREAN_DRIVER_LICENSE,
  KOREAN_ACCOUNT_COPY,
  BUSINESS_REGISTRATION_NUMBER = 8,
  NATIONAL_ID_CARD,
  NATIONAL_DRIVER_LICENSE,
  NRIC,
  FIN,
  KTP,
  KITAS
}
