
import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Mutation } from 'vuex-class'
import ManageTable from '@/components/shared/ManageTable.vue'
import RecipientList from '@/components/shared/RecipientList.vue'
import { manualApplyTransferOptions, applyTransferRules } from '@/data/ApplyTransferFormData'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import { RuleItem } from 'async-validator'
import Recipient from '@/models/recipient/Recipient'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import BaseCurrencyInfo from '@/components/remittance/BaseCurrencyInfo.vue'
import { Utils } from '@/static/Utils'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

@Component({
  components: { BaseCurrencyInfo, BasicDialog, RecipientList, ManageTable }
})
export default class ManualApply extends Vue {
  @Getter getManualValidatedTransfers: any
  @Mutation setRegisterTransfers: any

  countryWithMethods: Array<string> = []
  recipients: Dictionary<Array<Recipient>> = {}
  transferWithMethod: Dictionary<Array<TransferRow>> = {}
  transfers: Array<TransferRow> = []
  tableOption: Dictionary<ManageTableOptions> = manualApplyTransferOptions
  descriptor: Dictionary<RuleItem> = applyTransferRules
  baseCurrencyInfoDialog: boolean = false

  get hasUpdatingTransfers (): boolean {
    let hasUpdating = false
    this.countryWithMethods.forEach(countryWithMethod => {
      const isUpdating: boolean = this.transferWithMethod[countryWithMethod].some(recipient => {
        return !!recipient.updatable
      })
      hasUpdating = hasUpdating || isUpdating
    })
    return hasUpdating
  }

  getCountryMethodLabel (countryWithMethod: string): string {
    return Utils.getCountryMethodLabel(countryWithMethod)
  }

  openInfoModal () {
    this.baseCurrencyInfoDialog = true
  }

  deleteRow (countryWithMethod: string, index: number): void {
    this.transferWithMethod[countryWithMethod].splice(index, 1)
  }

  addSameRecipientRow (countryWithMethod: string, index: number): void {
    const slicedTransfer: TransferRow = this.transferWithMethod[countryWithMethod][index]
    const sameRecipientRow: TransferRow = Object.assign({}, slicedTransfer, {
      amount: '0',
      comment: '',
      invoice: { name: '', value: '' }
    })
    this.transferWithMethod[countryWithMethod].splice(index + 1, 0, sameRecipientRow)
  }

  toggleUpdatable (countryWithMethod: string, index: number, callback: any): void {
    const transferRow = this.transferWithMethod[countryWithMethod][index]
    transferRow.updatable = transferRow.updatable === true ? !transferRow.updatable : true
    if (callback) callback()
  }

  setTransfers (): void {
    this.countryWithMethods.forEach(countryWithMethod => {
      const transfer: Array<TransferRow> = this.transferWithMethod[countryWithMethod]
      this.transfers.push(...transfer)
    })
  }

  apply (): void {
    if (this.hasUpdatingTransfers) return
    this.setTransfers()
    this.setRegisterTransfers(this.transfers)
    this.$router.push('/transfer/register')
  }

  async created () {
    this.transferWithMethod = this.getManualValidatedTransfers
    if (!Object.keys(this.transferWithMethod).length) {
      this.$alert(`${this.$t('notification.wrong_approach')}`, 'WARNING', {
        type: 'warning',
        center: true
      })
      return this.$router.back()
    }
    this.countryWithMethods = Array(...Object.keys(this.transferWithMethod))
  }
}
